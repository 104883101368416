import {useEffect, useState} from "react";
import {baseApi} from "../../api/axios";

export default function ChargesComponent() {
    const [loading, setLoading] = useState(false);
    const [charges, setCharges] = useState([]);
    const getCharges = async () => {
        setLoading(true);
        try {
            var response = await baseApi('/charges');
            if (response.data?.charges != null) {
                setCharges(response.data?.charges);
            }
        } catch (e) {

        }

        setLoading(false);

    }
useEffect(()=>{
    getCharges()
},[])


    return <div className={'container'}>
        <div className="row justify-content-center">
            <div className="col-md-8">
                <div className="responsive-table">
                    <table className="table table-striped">
                        <thead>
                        <tr>
                            <th>صورة الإشعار</th>
                            <th>طريقة الشحن</th>
                            <th>القيمة</th>
                            <th>الحالة</th>
                            <th>التاريخ</th>
                        </tr>
                        </thead>
                        <tbody>
                        {charges?.length>0?charges.map((charge)=>  <tr>
                            <th>
                                <img style={{width:125,aspectRatio:1/1}} src={charge.img} alt=""/>
                            </th>
                            <th>{charge.bank}</th>
                            <th>{charge.amount}</th>
                            <th>{charge.status==='success'?'تم الشحن':charge.status==='cancel'?'ملغي':'بالإنتظار'}</th>
                            <th>{charge.created_at}</th>
                        </tr>):null}
                        </tbody>
                    </table>
                </div>
            </div>

        </div>
    </div>
}